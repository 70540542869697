<template>
     <div class="about">
          <div class="container ">
               <div class="columns ">
                    <div class="column">
                         <h1>
                              Contact us
                         </h1>

                         <div class="steps ">
                              <h4>Please reach out to us at</h4>
                              <div class="columns is-mobile is-vcentered contanctitem ">
                                   <div class="count">
                                        <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                  d="M28 5.90275V18.375C28 19.5353 27.5391 20.6481 26.7186 21.4686C25.8981 22.2891 24.7853 22.75 23.625 22.75H4.375C3.21468 22.75 2.10188 22.2891 1.28141 21.4686C0.460936 20.6481 0 19.5353 0 18.375V5.90275L13.5555 13.8792C13.6902 13.9587 13.8437 14.0006 14 14.0006C14.1563 14.0006 14.3098 13.9587 14.4445 13.8792L28 5.90275ZM23.625 5.41672e-08C24.7015 -0.000169253 25.7403 0.396561 26.5426 1.11429C27.3449 1.83203 27.8545 2.82037 27.9738 3.89025L14 12.11L0.0262502 3.89025C0.145527 2.82037 0.655067 1.83203 1.45739 1.11429C2.25972 0.396561 3.29849 -0.000169253 4.375 5.41672e-08H23.625Z"
                                             />
                                        </svg>
                                   </div>
                                   <div class="column">
                                        <div class="step">
                                             <a href="mailto:contactus@nestease.com" target="_blank"><h5>contactus@nestease.com</h5></a>
                                        </div>
                                   </div>
                              </div>

                              <h4>Follow us on</h4>
                              <div class="columns  is-mobile is-vcentered contanctitem">
                                   <div class="count">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                  d="M23.133 0H4C1.8 0 0 1.8 0 4V23.135C0 25.335 1.8 27.135 4 27.135H23.135C25.335 27.135 27.135 25.335 27.135 23.135V4C27.133 1.8 25.335 0 23.133 0ZM22.876 14.483H19.656V26.133H14.838V14.483H12.428V10.467H14.838V8.057C14.838 4.781 16.198 2.832 20.068 2.832H23.285V6.847H21.273C19.769 6.847 19.669 7.41 19.669 8.457L19.656 10.467H23.301L22.875 14.483H22.876Z"
                                             />
                                        </svg>
                                   </div>
                                   <div class="column">
                                        <div class="step">
                                             <a href="https://www.facebook.com/nestease.us/" target="_blank"><h5>Facebook</h5></a>
                                        </div>
                                   </div>
                              </div>

                              <div class="columns  is-mobile is-vcentered contanctitem">
                                   <div class="count">
                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M3.40075e-07 2.2975C3.40075e-07 1.68817 0.242058 1.10379 0.672922 0.672925C1.10379 0.24206 1.68817 3.06289e-06 2.2975 3.06289e-06H25.2C25.502 -0.000490127 25.8011 0.0585817 26.0802 0.173836C26.3593 0.289091 26.6129 0.458265 26.8266 0.671673C27.0402 0.885081 27.2097 1.13853 27.3252 1.41752C27.4408 1.6965 27.5002 1.99553 27.5 2.2975V25.2C27.5003 25.502 27.4411 25.8012 27.3257 26.0803C27.2103 26.3594 27.041 26.613 26.8275 26.8266C26.614 27.0403 26.3605 27.2097 26.0814 27.3252C25.8024 27.4408 25.5033 27.5002 25.2012 27.5H2.2975C1.99568 27.5 1.69682 27.4405 1.418 27.325C1.13917 27.2095 0.885839 27.0401 0.67248 26.8266C0.459121 26.6132 0.289915 26.3597 0.174528 26.0809C0.0591416 25.802 -0.00016387 25.5031 3.40075e-07 25.2013V2.2975ZM10.885 10.485H14.6088V12.355C15.1463 11.28 16.5212 10.3125 18.5875 10.3125C22.5487 10.3125 23.4875 12.4538 23.4875 16.3825V23.66H19.4788V17.2775C19.4788 15.04 18.9412 13.7775 17.5763 13.7775C15.6825 13.7775 14.895 15.1388 14.895 17.2775V23.66H10.885V10.485ZM4.01 23.4888H8.02V10.3125H4.01V23.4875V23.4888ZM8.59375 6.015C8.60131 6.35834 8.54022 6.69974 8.41405 7.01915C8.28788 7.33856 8.09919 7.62955 7.85904 7.87504C7.61889 8.12054 7.33212 8.31559 7.01557 8.44876C6.69901 8.58193 6.35905 8.65052 6.01563 8.65052C5.6722 8.65052 5.33224 8.58193 5.01568 8.44876C4.69913 8.31559 4.41236 8.12054 4.17221 7.87504C3.93206 7.62955 3.74337 7.33856 3.6172 7.01915C3.49103 6.69974 3.42994 6.35834 3.4375 6.015C3.45234 5.34107 3.73048 4.69974 4.21236 4.22836C4.69423 3.75698 5.34153 3.49303 6.01563 3.49303C6.68972 3.49303 7.33702 3.75698 7.81889 4.22836C8.30077 4.69974 8.57891 5.34107 8.59375 6.015Z"
                                             />
                                        </svg>
                                   </div>
                                   <div class="column">
                                        <div class="step">
                                             <a href="https://www.linkedin.com/company/nestease" target="_blank"><h5>Linkedin</h5></a>
                                        </div>
                                   </div>
                              </div>

                              <div class="columns is-mobile is-vcentered contanctitem">
                                   <div class="count">
                                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                  d="M14.0495 9.36667C11.469 9.36667 9.36316 11.4725 9.36316 14.053C9.36316 16.6335 11.469 18.7393 14.0495 18.7393C16.63 18.7393 18.7358 16.6335 18.7358 14.053C18.7358 11.4725 16.63 9.36667 14.0495 9.36667ZM28.105 14.053C28.105 12.1124 28.1225 10.1893 28.0135 8.25222C27.9046 6.00222 27.3913 4.00534 25.746 2.36003C24.0971 0.711202 22.1038 0.201436 19.8538 0.0924519C17.9132 -0.0165325 15.9901 0.0010457 14.053 0.0010457C12.1124 0.0010457 10.1893 -0.0165325 8.25222 0.0924519C6.00222 0.201436 4.00534 0.714718 2.36003 2.36003C0.711202 4.00886 0.201436 6.00222 0.0924519 8.25222C-0.0165325 10.1928 0.0010457 12.1159 0.0010457 14.053C0.0010457 15.9901 -0.0165325 17.9167 0.0924519 19.8538C0.201436 22.1038 0.714718 24.1007 2.36003 25.746C4.00886 27.3948 6.00222 27.9046 8.25222 28.0135C10.1928 28.1225 12.1159 28.105 14.053 28.105C15.9936 28.105 17.9167 28.1225 19.8538 28.0135C22.1038 27.9046 24.1007 27.3913 25.746 25.746C27.3948 24.0971 27.9046 22.1038 28.0135 19.8538C28.126 17.9167 28.105 15.9936 28.105 14.053ZM14.0495 21.2635C10.0592 21.2635 6.83894 18.0432 6.83894 14.053C6.83894 10.0628 10.0592 6.84245 14.0495 6.84245C18.0397 6.84245 21.26 10.0628 21.26 14.053C21.26 18.0432 18.0397 21.2635 14.0495 21.2635ZM21.5553 8.23112C20.6237 8.23112 19.8714 7.47878 19.8714 6.54714C19.8714 5.6155 20.6237 4.86316 21.5553 4.86316C22.487 4.86316 23.2393 5.6155 23.2393 6.54714C23.2396 6.76836 23.1962 6.98747 23.1117 7.1919C23.0272 7.39634 22.9031 7.58209 22.7467 7.73852C22.5903 7.89494 22.4045 8.01898 22.2001 8.10351C21.9957 8.18804 21.7766 8.2314 21.5553 8.23112Z"
                                             />
                                        </svg>
                                   </div>
                                   <div class="column">
                                        <div class="step">
                                             <a href="https://www.instagram.com/nestease.us/" target="_blank"><h5>Instagram</h5></a>
                                        </div>
                                   </div>
                              </div>

                              <div class="columns is-mobile is-vcentered contanctitem">
                                   <div class="count">
                                        <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                             <path
                                                  d="M27 2.60358C26.0071 3.04356 24.9405 3.34084 23.8191 3.47521C24.9761 2.78289 25.8417 1.69328 26.2544 0.409671C25.1674 1.05535 23.9777 1.50983 22.737 1.75337C21.9027 0.862563 20.7977 0.272122 19.5934 0.073716C18.3891 -0.12469 17.1531 0.0800391 16.0771 0.656118C15.0011 1.2322 14.1454 2.1474 13.6429 3.25962C13.1403 4.37185 13.019 5.61888 13.2979 6.8071C11.0952 6.69651 8.9405 6.12401 6.97346 5.12677C5.00643 4.12953 3.27106 2.72982 1.87999 1.0185C1.40434 1.83899 1.13085 2.79028 1.13085 3.8034C1.13032 4.71544 1.35491 5.61352 1.78471 6.41794C2.21451 7.22236 2.83623 7.90826 3.59469 8.41478C2.71507 8.38679 1.85487 8.14911 1.08566 7.72153V7.79287C1.08557 9.07205 1.52805 10.3119 2.33801 11.3019C3.14798 12.292 4.27554 12.9714 5.52938 13.2247C4.71339 13.4456 3.85788 13.4781 3.02748 13.3199C3.38124 14.4205 4.07033 15.383 4.99828 16.0726C5.92623 16.7621 7.04659 17.1443 8.2025 17.1655C6.24027 18.7059 3.81692 19.5414 1.32229 19.5377C0.880397 19.5379 0.438873 19.5121 0 19.4605C2.53219 21.0886 5.47984 21.9526 8.49027 21.9493C18.681 21.9493 24.252 13.5089 24.252 6.18876C24.252 5.95094 24.246 5.71074 24.2353 5.47291C25.3189 4.68926 26.2543 3.71885 26.9976 2.60715L27 2.60358Z"
                                             />
                                        </svg>
                                   </div>
                                   <div class="column">
                                        <div class="step">
                                             <a href="https://twitter.com/nestease_us" target="_blank"><h5>Twitter</h5></a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div class="column">
                         <img src="@/assets/images/contact/contact.jpg" alt="" class="contactimg" />
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .contactimg {
          @media screen and (max-width: 904px) {
               margin-top: 50px;
          }
     }

     .about {
          background: rgba(0, 0, 0, 0.02);
          padding: 100px 20px;

          @media screen and (max-width: 904px) {
               padding: 20px 20px 20px;
          }
     }

     h1 {
          margin-bottom: 10px;
     }
     h4 {
          margin-bottom: 5px;

          font-size: 25px;
     }

     .contanctitem {
          a {
               display: inline-block;
          }

          margin-top: 20px;
          h5 {
               font-size: 25px;
               position: relative;
               top: -3px;
               left: 10px;
               color: #03bc85;
               transition: all 0.5s;
               font-family: "Nunito Sans";
          }

          path {
               fill: #03bc85;
          }
          transition: all 0.5s;
     }

     .contanctitem:hover {
          path {
               fill: #000;
          }
          h5 {
               color: #000;
               text-decoration: underline;
          }
     }
</style>
