<template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">Privacy Policy</h1>
            <p class="date">Updated December 03, 2024</p>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container content">
        <p>
          This is our Privacy Policy, which explains how Nestease LLC, its
          subsidiaries and affiliates, personnel, and its contract service
          providers (the “Company,” “Nestease,” “we,” “our,” “us”) process your
          information with our website and our services (collectively, the
          “Services”).
        </p>

        <h2>1. INFORMATION WE MAY COLLECT</h2>
        <p>
          We will have access to non-public personal information from you or
          your partner/spouse when you sign up for an account to use our
          Service. This information may include, depending on how you use and
          interact with your account(s) with us and with other service
          providers:
        </p>
        <p>Identity: name</p>
        <p>Contact: email address, phone number, mailing or physical address</p>
        <p>
          Technological Information: Internet Protocol (IP) address, web
          browser, cookie identifiers, online identifier, other identifiers,
          websites, applications, or advertisements, and geolocation data.
        </p>
        <p>
          Additionally, by using the Services, you agree to our capture and
          storage of information regarding how you use the Services, your
          interactions with others, content you provide, and other content, and
          media, subject to your device settings and the ways in which you use
          your device with the Services. You consent to such disclosure of this
          information to assist us in operating the Services or to meet the
          requirements of any law or regulation (as deemed by us).
        </p>
        <p>We collect certain information automatically, including:</p>
        <p>
          Location: We collect data about users’ geographic location and IP
          addresses used when interacting with the Services.
        </p>
        <p>
          Tracking: Nestease and our third-party providers may automatically
          collect information when you visit or use our Services, read our
          emails, or otherwise engage with us. We typically use tracking
          technologies that include cookies, clear gifs, Flash objects, web
          beacons, embedded scripts, and similar technology (collectively,
          “Trackers”). For example, we may collect information about your
          preferences, analytics for the Services, your device and its operating
          system, your IP address, browser type, service provider, date and time
          stamp, and other similar information. We use Trackers to improve your
          experience and our Services. You may adjust settings on your web
          browser, your devices, and in your email service to change the way
          certain Trackers work, or to disable them. Changing these settings may
          limit the range of features available to you on our Services.
          Information provided by your browser developer and email provider will
          help you understand the limitations of these settings for you.
        </p>
        <p>
          Personal Information from Third Parties: We may also obtain personal
          information from third parties from time to time, such as connected
          apps, services, and devices, to the extent that you use such apps,
          services, and devices to connect with or use our Services. We will use
          publicly available data to capture information relevant to the
          provision of Services, such as the size of a real estate parcel,
          names, addresses, and square footage.
        </p>

        <h2>2. OUR USE OF YOUR INFORMATION.</h2>

        <p>
          We do not sell your personal information. We minimize the use of your
          data to only those purposes reasonably and practically necessary to
          provide our Services to you, and we collect only the amount and type
          of data necessary to provide these services. We share your information
          with third parties only in the ways and for the purposes listed below.
        </p>
        <p>
          We use the information we collect for purposes described in this
          Privacy Policy and as described to you on our Terms of Service. This
          includes using the information to:
        </p>
        <p>
          Provide the Services to you, allowing you to use the Services and
          participate in interactive features and for Nestease to handle billing
          and account management, fulfill our legal and regulatory obligations,
          and to complete other administrative matters;
        </p>
        <p>
          Provide information to you including hypothetical calculation results
          specific to information input by you, and to create and communicate
          invoices, technical notices, services information and announcements,
          software updates, security alerts, support and administrative
          messages, information about your transactions with us, payments and
          amounts owed, responses to comments and questions, newsletters or
          other content, customer service or feedback, or any other information
          related to the Services;
        </p>
        <p>
          Conduct research and review analytics to understand user activity, to
          test, and to improve our actual and potential product offerings;
        </p>
        <p>
          Perform audits and protect or exercise our legal rights or defend
          against legal claims, including to enforce and carry out contracts and
          agreements; and
        </p>
        <p>Comply with laws, regulations, and legal obligations.</p>

        <h2>3. DISCLOSURES</h2>
        <p>
          Consistent with the collection practices and purposes described in
          this policy, we may share your personal information for certain
          purposes. We may share your personal information with:
        </p>
        <p>
          Our third-party vendors and service providers. We share personal data
          with our vetted contractors, subcontractors, third-party vendors, and
          service providers who help us run and protect the Services and our
          business.
        </p>
        <p>
          Our business transactions. We may share personal data as reasonably
          necessary in the event of a company business transaction, such as the
          sale of our assets, a merger, consolidation, reorganization,
          financing, change of control or acquisition between our company and
          another company or third party, an initial public offering, or
          bankruptcy or similar proceeding.
        </p>
        <p>
          Otherwise, as you agree. We may share personal data for other purposes
          with your consent.
        </p>
        <p>
          As legally required. We will disclose personal data to respond to
          subpoenas, court orders, legal process, law-enforcement requests,
          legal claims, or government inquiries and to protect and defend the
          rights, interests, safety, and security of Nestease, our related
          companies and integral third parties, users, or the public.
        </p>
        <p>
          We may also share information with others in an aggregated or
          otherwise anonymized form that does not reasonably identify you
          directly as an individual.
        </p>
        <p>
          For further information on your choices regarding your personal
          information, see the “Controlling Your Information” section of this
          policy, below.
        </p>

        <h2>4. THIRD-PARTY PLATFORMS AND ACTIONS</h2>
        <p>
          Our Service relies upon the interchange of data with you and
          third-party technologies. Some of the Services capture and use content
          and data provided or maintained by third parties. These include but
          are not limited to content delivery networks used by us or by you. We
          are not responsible for the privacy practices of these third parties
          or the content provided by third-party services, and the information
          practices of these third parties are not covered by this Policy.
          Please note that these third-party services have their own privacy
          policies, and we strongly recommend that you read their privacy
          policies as well as terms and conditions of use to understand how they
          collect, use, and share your information. The primary third parties
          that we partner with are:
        </p>

        <ul>
          <li>Partnership firm name/website</li>
        </ul>

        <p>
          By using our Services, you are agreeing that you are aware of and
          agree to be bound by the privacy policies of the advertising networks
          with which our Services work. These networks and their policies are:
        </p>

        <p>Google Ads : <a href="https://support.google.com/adspolicy">https://support.google.com/adspolicy</a></p>
        <p>
          Meta (Facebook and Instagram):
          <a href="https://transparency.meta.com/policies/ad-standards/">https://transparency.meta.com/policies/ad-standards/</a>
        </p>
        <p>
          NextDoor: <a href="https://help.nextdoor.com/s/article/Advertising-Terms-of-Service?language=en_US">https://help.nextdoor.com/s/article/Advertising-Terms-of-Service?language=en_US</a>
        </p>
        <p>LinkedIn: <a href="https://www.linkedin.com/legal/user-agreement">https://www.linkedin.com/legal/user-agreement</a></p>

        <h2>5. CONTROLLING YOUR INFORMATION</h2>
        <p>
          We use our best efforts to safeguard against loss, theft,
          interference, misuse, and unauthorized access to your data. However,
          you should understand that data transmission and storage over the
          internet or any public network can never be 100% secure, accurate,
          complete, or current.
        </p>

        <p>
          We will honor your privacy requests, including by allowing you to
          manage or review your account information, marketing preferences, and
          user data. For any of these controls or opt-outs, please contact
          contactus@nestease.com
        </p>

        <ul>
          <li>
            to edit your account information and settings, including any profile
            and contact data
          </li>
          <li>to unsubscribe from marketing messages or newsletters</li>
          <li>
            to view geo-location information associated with your account, and
            to request its deletion following termination of your account
          </li>
          <li>
            to delete your account login credentials, make your account
            inaccessible, and/or remove or anonymize any content you have posted
            to our Site or shared with others on our Site
          </li>
          <li>to request a machine-readable copy of your personal data</li>
        </ul>

        <p>We honor the following SMS guidelines:</p>

        <ul>
          <li>
            We will not send more than 50 messages per originating number per
            minute
          </li>
          <li>
            If we send marketing, promotional, or informational messages, we
            will do so only with written consent of recipients
          </li>
          <li>
            We do not use purchased lists or third-party generated lead lists
            when sending SMS
          </li>
          <li>
            We will provide clear opt-out instructions on informational,
            marketing, or promotional messages
          </li>
          <li>
            We will avoid using link shorteners and non-branded domains for
            embedded links
          </li>
          <li>
            We will complete the required registration with The Campaign
            Registry (TCR) prior to sending SMS messages
          </li>
        </ul>

        <h2>6. TRANSFER OF PERSONAL INFORMATION</h2>

        <p>
          We are based in the United States, and our Services are designed for
          use only in the United States. If you are using our Services from
          another jurisdiction, your information collected through our Services
          may be stored and processed in the United States or any other country
          in which Nestease or its service providers maintain facilities or
          conduct business activities. If you are located in other regions with
          laws governing data collection and use that may differ from U.S. law,
          please note that we may transfer information, including personal
          information, to a country and jurisdiction that does not have the same
          data-protection laws. In the event that the Company, a controlling
          interest in the Company, or all or substantial portions of the
          Company’s assets are acquired by a third party, your data and the
          corresponding commitments of this policy may be transferred as part of
          such a transaction.
        </p>

        <h2>7. CALIFORNIA RESIDENTS</h2>

        <p>
          California residents have certain rights under the California Consumer
          Privacy Act (“CCPA”). Please note that the CCPA does not apply to
          non-public personal information collected by financial institutions,
          which is regulated by other laws.
        </p>

        <p>
          Subject to limitations and exceptions, the CCPA provides California
          residents the right to request details about the categories and
          specific elements of personal information we collect, to delete their
          personal information, to opt out of any “sales” that may be occurring,
          and to not be discriminated against for exercising these rights.
          Choices available to you for the collection and handling of your
          information are addressed throughout this policy.
        </p>

        <p>
          In the last 12 months, we collected the following categories of
          personal information subject to the CCPA: identifiers (such as email
          address and IP address), individual identity and contact information,
          approximate geolocation information, and Internet or other electronic
          network activity information (such as browsing history and related
          usage data).
        </p>

        <p>
          For more details about the information we collect and the categories
          of sources of this information, please see the above sections of this
          policy. We share this information with the categories of third parties
          described in the “Disclosures” section above.
        </p>

        <p>
          California residents may make a request for access to certain personal
          information subject to the CCPA, not otherwise exempted as non-public
          personal information collected by financial institutions, by sending
          an email to contactus@nestease.com 804-292-4768. We may verify any
          such request by asking you to provide information that matches
          information we have on file about you. You can also designate an
          authorized agent to exercise these rights on your behalf, but we will
          require proof that the person is authorized to act on your behalf and
          may also still ask you to verify your identity with us directly.
        </p>

        <h2>8. DATA RETENTION</h2>
        <p>
          We may maintain personal information in our databases until you ask us
          to remove your information. Even after you ask that we remove your
          information from our databases, it may be impossible to remove some
          information, such as web server log files and information that may
          reside in backup files. We also may retain technical information for
          use in the aggregate and to analyze and improve the Services. Finally,
          we may maintain information as part of a document retention policy to
          comply with applicable laws, rules, guidelines, or regulations; to
          protect ourselves against liability; prevent fraudulent activity; to
          enforce our contracts; and to permit us to pursue available remedies
          and limit damages.
        </p>

        <h2>9. CHILDREN’S PRIVACY</h2>

        <p>
          We do not knowingly solicit or collect information from anyone under
          the age of 13. In the event that we learn that we have inadvertently
          collected personal information from someone under age 13, we will take
          reasonable steps to delete that information. If you believe that we
          might have any information from a child under 13, please contact us at
          contactus@nestease.com
        </p>

        <h2>10. CHANGES TO THIS POLICY</h2>

        <p>
          This Privacy Policy will evolve with time, and when we update it, we
          will revise the "Effective Date" above and post the new Policy. To
          stay informed of our privacy practices, we recommend you review the
          Policy on a regular basis as you continue to use our Services. Your
          use of our Site following a revision to this Policy is your agreement
          to this Policy, as revised.
        </p>

        <h2>11. CONTACT US</h2>

        <p>
          If you have any questions about this Privacy Policy or the Services,
          please contact us at contactus@nestease.com or by phone at
          804-292-4768
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$refs.video.play();
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/privacy/privacy-desk-bg.png");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;
  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.content {
  padding-top: 50px;
  padding-bottom: 100px;
  h2 {
    font-weight: 500;
    max-width: 650px;
    margin-top: 50px !important;
  }

  p {
    max-width: 650px;
    text-align: justify;
  }

  li {
    text-align: justify;
    max-width: 500px;
    margin-bottom: 20px;
    font-weight: 400;
  }

  @media screen and (max-width: 700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.container {
  padding-left: 16px;
  padding-right: 16px;
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  p.date {
    color: white;
    font-size: 20px;
    align-self: center;
    margin-top: 20px;
    font-weight: bold;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }
}
</style>
