<template>
  <div>
    <div class="">
      <div class="banner">
        <div class="container">
          <div class="header-div">
            <h1 class="header-text">Investor</h1>
            <img class="header-logo" src="../assets/images/logo.png" />
          </div>
        </div>
      </div>

      <div class="container header-content">
        <h1 class="welcome-text">Secure Your Future with Smart Investments</h1>

        <p class="thankYou">
          Nestease is revolutionizing the home services industry by combining
          technology and skilled in-house professionals to deliver seamless,
          high-quality experiences. Our innovative platform empowers customers
          with flexible, personalized solutions for essential home needs like
          lawn care, snow removal, house cleaning, and others. Join us in
          shaping the future of home services.
        </p>

        <a class="contect-com" href="mailto:contactus@nestease.com" target="_blank">contactus@nestease.com</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // this.$refs.video.play();
  },
};
</script>

<style scoped lang="scss">
.banner {
  background: url("../assets/images/investor/investor-desk-bg.png");
  height: 650px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  display: flex;
  align-items: center;
  background-size: cover;
  h1 {
    color: white;
    font-size: 80px;
    text-align: center;
  }
}

.nester {
  height: 550px;
  .img-style {
    height: 100%;
    width: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.ourstoryImage {
  background: url("../assets/images/about_us/Container.jpg");
  @media screen and (max-width: 800px) {
    background: url("../assets/images/about_us/Container_m.png");
  }
}
.founder {
  background: url("../assets/images/career/software-bg.png");
  @media screen and (max-width: 600px) {
    background-image: url("../assets/images/career/software-bg.png");
  }
}

.flexibility {
  background: url("../assets/images/about_us/Flexibility.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/Flexibility_m.png");
  }
}

.ourInHouse {
  background: url("../assets/images/career/product-bg.png");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/career/product-bg.png");
  }
}

.lookingToward {
  background: url("../assets/images/about_us/LookingToward.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/LookingToward_m.png");
  }
}

.ourCustomers {
  background: url("../assets/images/about_us/OurCustomers.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurCustomers_m.png");
  }
}

.ourPartners {
  background: url("../assets/images/about_us/OurPartners.jpg");
  @media screen and (max-width: 600px) {
    background: url("../assets/images/about_us/OurPartners_m.png");
  }
}

.content {
  padding-top: 70px;
  padding-bottom: 70px;
  .welcome-text {
    font-weight: 500;
    margin-bottom: 40px;
  }
  .thankYou {
    font-weight: 500;
    font-size: 20px;
    max-width: 740px;
    margin: auto;
  }
  // max-width: 700px !important;
  h1 {
    display: flex;
    justify-content: start;
  }

  h2 {
    font-weight: 500;
    text-align: start;
  }

  p {
    text-align: start;
    font-size: 18px;
  }

  @media screen and (max-width: 700px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.custpartner {
  // margin: 50px auto 130px;
  padding: 130px 288px;
  background-color: #eff8f5;

  h1 {
    font-size: 50px;
    max-width: 100%;
  }

  .content {
    padding: 60px 30px;
  }

  @media screen and (max-width: 1400px) {
    padding: 100px 16px;
  }

  @media screen and (max-width: 769px) {
    padding: 100px 16px;
  }
}

.container {
  padding: 130px 0px;
  max-width: 1030px;
  margin: auto;
  // padding-left: 16px;
  // padding-right: 16px;
  .width-50 {
    width: 50%;
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }

  // align-items: center;
  .header-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-text {
    color: #fff;
    font-size: 45px;
  }

  .header-logo {
    height: 180px;
    width: auto;
    margin-top: 100px;
  }

  @media screen and (max-width: 1070px) {
    padding: 130px 16px;
    max-width: 1030px;
    margin: auto;
  }
}
.header-content {
  display: flex;
  flex-direction: column;
  gap: 16px;

  h1 {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: 35px;
  }

  p.thankYou {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 18px;
  }

  a.contect-com {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #0b0d17;
    cursor: pointer;
  }
}
</style>
